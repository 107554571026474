import PageSpinner from 'src/components/PageSpinner';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';

import 'src/styles/reduction.scss';

const AccountsPage = React.lazy(() => import('src/pages/Accounts/AccountsPage'));
const AuthRoute = React.lazy(() => import('src/components/AuthRoute'));
const CommunityPage = React.lazy(() => import('src/pages/Community/CommunityPage'));
const DailyRunsPage = React.lazy(() => import('src/pages/DailyRuns/DailyRunsPage'));
const FuturesEdit = React.lazy(() => import('src/pages/FuturesAdmin/FuturesEdit'));
const FuturesManagePage = React.lazy(() => import('src/pages/FuturesAdmin/FuturesManagePage'));
const HomePage = React.lazy(() => import('src/pages/HomePage'));
const IndicatorsEditPage = React.lazy(() => import('src/pages/Indicators/IndicatorsEditPage'));
const IndicatorsManagePage = React.lazy(() => import('src/pages/Indicators/IndicatorsManagePage'));
const LoginPage = React.lazy(() => import('src/pages/LoginPage'));
const MainLayout = React.lazy(() => import('src/components/Layout/MainLayout'));
const PositionsPage = React.lazy(() => import('src/pages/Positions/PositionsPage'));
const PortfoliosEditPage = React.lazy(() => import('src/pages/Portfolios/PortfolioEdit'));
const PortfoliosManagePage = React.lazy(() => import('src/pages/Portfolios/PortfoliosManage'));
const RegisterPage = React.lazy(() => import('src/pages/RegisterPage'));
const ScreenerManagePage = React.lazy(() => import('src/pages/Screener/ScreenerManagePage'));
const SettingsPage = React.lazy(() => import('src/pages/Settings/SettingsPage'));
const SimulationsManagePage = React.lazy(() => import('src/pages/Simulations/SimulationPage'));
const StrategiesManagePage = React.lazy(() => import('src/pages/Strategies/StrategiesManagePage'));
const StrategiesEditPage = React.lazy(() => import('src/pages/Strategies/StrategiesEditPage'));
const ViewStudy = React.lazy(() => import('src/pages/Strategies/Study/ViewStudy'));
const ViewStudyStrategy = React.lazy(() => import('src/pages/Strategies/Study/ViewStudyStrategy'));

const defaultMaterialTheme = createTheme();

export const App = (props: any) => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultMaterialTheme}>
        <BrowserRouter>
          <Suspense fallback={<PageSpinner />}>
            <MainLayout>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/signup"
                  element={
                    <AuthRoute>
                      <RegisterPage />
                    </AuthRoute>
                  }
                />
                <Route path="/" element={<HomePage />} />
                <Route
                  path="/screeners/manage"
                  element={
                    <AuthRoute>
                      <ScreenerManagePage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/portfolios/manage"
                  element={
                    <AuthRoute>
                      <PortfoliosManagePage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/portfolios/edit/:id"
                  element={
                    <AuthRoute>
                      <PortfoliosEditPage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/strategies/manage"
                  element={
                    <AuthRoute>
                      <StrategiesManagePage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/strategies/edit/:id"
                  element={
                    <AuthRoute>
                      <StrategiesEditPage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/strategies/edit"
                  element={
                    <AuthRoute>
                      <StrategiesEditPage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/indicators/manage"
                  element={
                    <AuthRoute>
                      <IndicatorsManagePage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/indicators/edit/:id"
                  element={
                    <AuthRoute>
                      <IndicatorsEditPage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/indicators/edit"
                  element={
                    <AuthRoute>
                      <IndicatorsEditPage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/strategies/study/:id"
                  element={
                    <AuthRoute>
                      <ViewStudy />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/strategies/studystrategy/:id"
                  element={
                    <AuthRoute>
                      <ViewStudyStrategy />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/futuresspecs"
                  element={
                    <AuthRoute>
                      <FuturesManagePage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/futuresspecs/edit/:id"
                  element={
                    <AuthRoute>
                      <FuturesEdit />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/simulations/manage"
                  element={
                    <AuthRoute>
                      <SimulationsManagePage />
                    </AuthRoute>
                  }
                />

                <Route
                  path="/positions"
                  element={
                    <AuthRoute>
                      <PositionsPage />
                    </AuthRoute>
                  }
                />

                <Route
                  path="/dailyruns"
                  element={
                    <AuthRoute>
                      <DailyRunsPage />
                    </AuthRoute>
                  }
                />

                <Route
                  path="/accounts"
                  element={
                    <AuthRoute>
                      <AccountsPage />
                    </AuthRoute>
                  }
                />

                <Route
                  path="/settings"
                  element={
                    <AuthRoute>
                      <SettingsPage />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/community"
                  element={
                    <AuthRoute>
                      <CommunityPage />
                    </AuthRoute>
                  }
                />
                {/* <AuthRoute exact path="/demos/dashboard" component={DashboardPage} />
              <AuthRoute exact path="/demos/cards" component={CardPage} />
              <AuthRoute exact path="/demos/charts" component={ChartPage} />
              <AuthRoute exact path="/demos/widgets" component={WidgetPage} />
              <AuthRoute exact path="/demos/buttons" component={ButtonPage} />
              <AuthRoute exact path="/demos/button-groups" component={ButtonGroupPage} />
              <AuthRoute exact path="/demos/forms" component={FormPage} />
              <AuthRoute exact path="/demos/input-groups" component={InputGroupPage} />
              <AuthRoute exact path="/demos/dropdowns" component={DropdownPage} />
              <AuthRoute exact path="/demos/badges" component={BadgePage} />
              <AuthRoute exact path="/demos/alerts" component={AlertPage} />
              <AuthRoute exact path="/demos/progress" component={ProgressPage} />
              <AuthRoute exact path="/demos/modals" component={ModalPage} />
              <AuthRoute exact path="/demos/typography" component={TypographyPage} />
              <AuthRoute exact path="/demos/tables" component={TablePage} /> */}
                {/* <AuthRoute exact path="/demos/login-modal" component={AuthModalPage} /> */}
              </Routes>
            </MainLayout>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
};

// const query = ({ width }: any) => {
//   if (width < 575) {
//     return { breakpoint: 'xs' };
//   }

//   if (576 < width && width < 767) {
//     return { breakpoint: 'sm' };
//   }

//   if (768 < width && width < 991) {
//     return { breakpoint: 'md' };
//   }

//   if (992 < width && width < 1199) {
//     return { breakpoint: 'lg' };
//   }

//   if (width > 1200) {
//     return { breakpoint: 'xl' };
//   }

//   return { breakpoint: 'xs' };
// };

// export componentQueries(query)(App);

export default App;
