import 'bootstrap/dist/css/bootstrap.css';
//import * as React from 'react';
//import * as ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import '@fontsource/eb-garamond';
// const history = createBrowserHistory();
//import registerServiceWorker from './registerServiceWorker';

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(<App />);
// ReactDOM.render(
//   <HashRouter>
//     <App />
//   </HashRouter>,
//   rootElement
// );

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
// window.console.log(`NODE_ENV=${process.env.NODE_ENV}`);
if (process.env.NODE_ENV === 'production') {
  registerServiceWorker();
}
